// dependencies
import $ from 'jquery'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import { hacks } from 'viewport-units-buggyfill'

// modules
import module from './modules/module'
import scroll from './modules/scroll'
import webfonts from './modules/webfonts'
import googlemaps from './modules/googlemaps'
import slider from './modules/slider'
import toggleCurrencyRate from './modules/toggle-currency-rate'

// init
$(document).ready(() => {

	// viewportunit polyfill
	viewportUnitsBuggyfill.init({
		hacks: hacks
	})

})

// VueJS
var app = new Vue({
	el: '#app',
	data: {
		agb: false,
		currentDate: '',
		legal: false,
		privacyPolicy: false,
		showCopayInstructionsModal: false,
		showModal: false,
		showNoWalletModal: false,
		showWalletModal: false,
	},
	methods: {
		currentYear() {
			let date = new Date();
			let year = date.getFullYear();
			this.currentDate = year;
		}
	},
	mounted() {
		this.currentYear()
	}
})
