import $ from 'jquery'
// import waypoints from 'waypoints/lib/jquery.waypoints.js'

var Module = (() => {

	var init = function() {
        $('.c-menu__link').on('click', function(e){
            var href = $(this).attr('href');
            $('html, body').animate({
                scrollTop:$(href).offset().top
            },'slow');
            e.preventDefault();
        });


	}

	return {
		init: init
	}

})()

$(document).ready(Module.init)
