import $ from 'jquery'
import slick from 'slick-carousel'

var Module = (() => {

	var element = {}

	var init = function() {
		$('.c-slideshow--card .slider').slick({
			infinite: false,
			prevArrow: '.c-slideshow--card__prev',
			nextArrow: '.c-slideshow--card__next'
		});

		$('.c-slideshow--lightbox').slick({
			mobileFirst: true,
			infinite: false,
			variableWidth: true
		});
	}

	return {
		init: init
	}

})()

$(document).ready(Module.init)
