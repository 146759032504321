import $ from 'jquery'

var Module = (() => {

	var element = {}

	var init = function() {

		$('.c-currency-rate__switch').click(function() {
			$('.c-currency-rate__item').toggleClass('c-currency-rate__item--hidden');
		});
	}

	return {
		init: init
	}

})()

$(document).ready(Module.init)
