import WebFont from 'webfontloader'

WebFont.load({
	custom: {
	    families: [
			'AcherusGrotesque-Black',
			'AcherusGrotesque-Light',
			'AcherusGrotesque-Medium'
		],
	    urls: [
			'assets/fonts/acherus-grotesque-black.css',
			'assets/fonts/acherus-grotesque-light.css',
			'assets/fonts/acherus-grotesque-medium.css'
		]
	}
});
