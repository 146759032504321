import $ from 'jquery'

var Module = (() => {

	var element = {}

	var init = function() {

	}

	return {
		init: init
	}

})()

$(document).ready(Module.init)
